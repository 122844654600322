$content-slider-slide-gap                 : 20px !default;

$content-slider-arrows-bg-color           : color(white) !default;
$content-slider-arrows-color              : color(base) !default;
$content-slider-arrows-font-size          : 22px !default;
$content-slider-arrows-position           : calc(100% / 2) !default;
$content-slider-arrows-width              : 40px !default;
$content-slider-arrows-height             : 100px !default;
$content-slider-arrows-prev-border-radius : 0 border-radius(s) border-radius(s) 0 !default;
$content-slider-arrows-next-border-radius : border-radius(s) 0 0 border-radius(s) !default;

$content-slider-full-width                : calc(100% + 40px) !default;
$content-slider-full-width-margin-left    : -20px !default;

.c-content-slider {
    width       : $content-slider-full-width;
    margin-left : $content-slider-full-width-margin-left;
    position    : relative;

    @media only screen and (min-width : 768px) {
        width       : 100%;
        margin-left : 0;
    }

    .glider {
        scrollbar-width : none;

        &::-webkit-scrollbar {
            display : none;
        }
    }

    .glider-track > div {
        margin-left : $content-slider-slide-gap;

        &:last-child {
            margin-right: 20px;
        }
    }

    .c-content-slider__arrows {
        background-color : $content-slider-arrows-bg-color;
        border           : none;
        position         : absolute;
        top              : $content-slider-arrows-position;
        transform        : translateY(-50%);
        cursor           : pointer;
        width            : $content-slider-arrows-width;
        height           : $content-slider-arrows-height;
        display          : none;
        font-size        : $content-slider-arrows-font-size;
        color            : $content-slider-arrows-color;

        &.c-content-slider__arrows--prev {
            border-radius : $content-slider-arrows-prev-border-radius;
        }

        &.c-content-slider__arrows--next {
            right         : 0;
            border-radius : $content-slider-arrows-next-border-radius;
        }

        &.disabled {
            display : none;
        }

        @media only screen and (min-width : 1024px) {
            display         : flex;
            justify-content : center;
            align-items     : center;
        }

        .c-icon:before {
            margin : 0;
        }
    }
}

.is-full-width {
    width       : $content-slider-full-width;
    margin-left : $content-slider-full-width-margin-left;

    @media only screen and (min-width : 768px) {
        width       : 100%;
        margin-left : 0;
    }
}
